module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jaen App","short_name":"Jaen","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"src/favicon.ico","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ac8eafd5d838425b04e928ce213cb051"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":[],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"sampleRate":1,"enableTracing":true,"debug":true,"tracesSampleRate":1,"replaysSessionSampleRate":1,"replaysOnErrorSampleRate":1,"dsn":"https://d09bdda1cf0a74ccc8f1041bf6ba8d0d@sentry.cronit.io/11"},
    },{
      plugin: require('../node_modules/gatsby-plugin-jaen/gatsby-browser.tsx'),
      options: {"plugins":[],"remote":{"repository":"getcronit/cronit.io"},"zitadel":{"organizationId":"252746033782587395","clientId":"252746210698395651@services","authority":"https://accounts.cronit.io","redirectUri":"https://cronit.io","projectIds":["252765861113233411","252899191242620931","260237544631828483"]},"sentry":{"org":"cronit","project":"cronit-io","dsn":"https://d09bdda1cf0a74ccc8f1041bf6ba8d0d@sentry.cronit.io/11"},"googleAnalytics":{}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
