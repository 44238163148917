import { useId } from "react";
import clsx from "clsx";

export function Logomark({ invert = false, filled = false, ...props }) {
  let id = useId();

  return (
    <svg viewBox="0 0 32 32" aria-hidden="true" {...props}>
      <rect
        clipPath={`url(#${id}-clip)`}
        className={clsx(
          "h-8 transition-all duration-300",
          invert ? "fill-white" : "fill-neutral-950",
          filled ? "w-8" : "w-0 group-hover/logo:w-8"
        )}
      />
      <use
        href={`#${id}-path`}
        className={invert ? "stroke-white" : "stroke-neutral-950"}
        fill="none"
        strokeWidth="1.5"
      />
      <defs>
        <path
          id={`${id}-path`}
          d="M3.25 26v.75H7c1.305 0 2.384-.21 3.346-.627.96-.415 1.763-1.02 2.536-1.752.695-.657 1.39-1.443 2.152-2.306l.233-.263c.864-.975 1.843-2.068 3.071-3.266 1.209-1.18 2.881-1.786 4.621-1.786h5.791V5.25H25c-1.305 0-2.384.21-3.346.627-.96.415-1.763 1.02-2.536 1.751-.695.658-1.39 1.444-2.152 2.307l-.233.263c-.864.975-1.843 2.068-3.071 3.266-1.209 1.18-2.881 1.786-4.621 1.786H3.25V26Z"
        />
        <clipPath id={`${id}-clip`}>
          <use href={`#${id}-path`} />
        </clipPath>
      </defs>
    </svg>
  );
}

export function Logo({
  className,
  invert = false,
  filled = false,
  fillOnHover = false,
  ...props
}) {
  return (
    <svg
      aria-hidden="true"
      className={clsx(
        "w-[130px] h-[32px]",
        fillOnHover && "group/logo",
        className
      )}
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="3196.72131147541"
      height="1096.9876517853854"
      viewBox="0 0 3196.72131147541 1096.9876517853854"
    >
      <g transform="scale(9.836065573770492) translate(10, 10)">
        <defs id="SvgjsDefs1185" />
        <g
          id="SvgjsG1186"
          transform="matrix(6.033965917459257,0,0,6.033965917459257,1.1382626184474405,-30.117685552630938)"
          className={invert ? "fill-white" : "fill-neutral-950"}
        >
          <path d="M7.9 16.46 l2.08 1.88 c-1.12 1.24 -2.58 1.82 -4.14 1.82 c-2.88 0 -5.2 -2.16 -5.2 -5.08 s2.32 -5.1 5.2 -5.1 c1.56 0 3.02 0.58 4.14 1.82 l-2.08 1.88 c-0.44 -1.02 -1.4 -1.26 -2.06 -1.26 c-1.22 0 -2.24 1.08 -2.24 2.66 c0 1.56 1.02 2.64 2.24 2.64 c0.66 0 1.64 -0.18 2.06 -1.26 z M17.747708333333332 10.26 l-0.4 2.22 c-0.74 -0.32 -0.88 -0.26 -1.22 -0.26 c-1.2 0 -1.9 0.64 -1.9 2.22 l0 5.56 l-2.96 0 l0 -9.88 l2.96 0 l0 0.88 c0.38 -0.68 1.42 -1.02 2.08 -1.02 c0.62 0 0.98 0.02 1.44 0.28 z M22.815416666666664 12.42 c-1.22 0 -2.24 1.08 -2.24 2.66 c0 1.56 1.02 2.64 2.24 2.64 c1.24 0 2.26 -1.08 2.26 -2.64 c0 -1.58 -1.02 -2.66 -2.26 -2.66 z M22.815416666666664 9.98 c2.9 0 5.22 2.18 5.22 5.1 s-2.32 5.08 -5.22 5.08 c-2.88 0 -5.2 -2.16 -5.2 -5.08 s2.32 -5.1 5.2 -5.1 z M38.423125 14.36 l0 5.64 l-2.96 0 l0 -5.64 c0 -1.5 -0.4 -2.16 -1.56 -2.16 c-1.2 0 -1.9 0.66 -1.9 2.16 l0 5.64 l-2.96 0 l0 -9.88 l2.96 0 l0 0.94 c0.76 -0.74 1.8 -1.08 2.66 -1.08 c2.78 0 3.76 1.34 3.76 4.38 z M42.87083333333333 10.12 l0 9.88 l-2.96 0 l0 -9.88 l2.96 0 z M42.95083333333333 7.279999999999999 c0 0.84 -0.68 1.5 -1.58 1.5 c-0.86 0 -1.56 -0.66 -1.56 -1.5 c0 -0.82 0.7 -1.46 1.56 -1.46 c0.9 0 1.58 0.64 1.58 1.46 z M48.018541666666664 15.8 c0 0.72 -0.02 1.92 1.08 1.92 c0.54 0 0.88 -0.22 1.26 -0.5 l0 2.44 c-0.46 0.26 -1.02 0.5 -1.62 0.5 c-2.54 0 -3.68 -1.08 -3.68 -4.36 l0 -3.24 l-1.1 0 l0 -2.44 l1.1 0 l0 -2.84 l2.96 0 l0 2.84 l2.34 0 l0 2.44 l-2.34 0 l0 3.24 z" />
        </g>
      </g>
    </svg>
  );

  return (
    <svg
      viewBox="0 0 130 32"
      aria-hidden="true"
      className={clsx(fillOnHover && "group/logo", className)}
      {...props}
    >
      <Logomark
        preserveAspectRatio="xMinYMid meet"
        invert={invert}
        filled={filled}
      />
      <path
        className={invert ? "fill-white" : "fill-neutral-950"}
        d="M52.928 23.716c5.184 0 7.992-1.992 7.992-5.28 0-3.888-2.688-4.8-7.512-5.376-3.36-.408-4.728-.672-4.728-2.448 0-1.464 1.44-2.376 3.912-2.376 2.4 0 3.936.864 4.104 2.784h3.576c-.24-3.288-3-5.232-7.536-5.232-4.728 0-7.68 1.896-7.68 5.208 0 3.48 2.712 4.464 7.416 5.04 3.216.408 4.8.648 4.8 2.664 0 1.584-1.392 2.544-4.224 2.544-3.048 0-4.68-1.176-4.752-3.288H44.6c.072 3.408 2.616 5.76 8.328 5.76Zm14.175-.216h3.312v-2.928h-1.968c-.84 0-1.272-.24-1.272-1.104v-6.144h3.24v-2.592h-3.24V6.676l-3.36.648v3.408h-2.496v2.592h2.496v7.2c0 2.04 1.248 2.976 3.288 2.976Zm10.078.216c2.16 0 4.104-1.008 4.944-2.64h.168l.144 2.424h3.288V10.732h-3.432v6.336c0 2.4-1.584 4.032-3.984 4.032-2.328 0-3.264-1.368-3.264-3.936v-6.432h-3.432v7.032c0 4.416 2.256 5.952 5.568 5.952Zm16.24.048c2.52 0 4.2-1.008 4.944-2.496h.168l.072 2.232h3.264V6.004h-3.408v7.008h-.168c-.792-1.56-2.592-2.52-4.848-2.52-3.816 0-6.384 2.592-6.384 6.624 0 4.056 2.568 6.648 6.36 6.648Zm1.032-2.616c-2.472 0-3.96-1.536-3.96-4.032s1.488-4.008 3.96-4.008 3.984 1.512 3.984 3.648v.744c0 2.136-1.536 3.648-3.984 3.648Zm9.485-12.216h3.408V6.004h-3.408v2.928Zm0 14.568h3.408V10.732h-3.408V23.5Zm12.481.24c4.584 0 7.56-2.52 7.56-6.624 0-4.152-3-6.624-7.56-6.624s-7.56 2.52-7.56 6.624c0 4.128 3.024 6.624 7.56 6.624Zm0-2.64c-2.592 0-4.128-1.56-4.128-3.984s1.536-3.984 4.128-3.984c2.616 0 4.152 1.536 4.152 3.984 0 2.424-1.56 3.984-4.152 3.984Zm8.794 2.4h3.384v-2.88h-3.384v2.88Z"
      />
      <g transform="scale(9.836065573770492) translate(10, 10)">
        <defs id="SvgjsDefs1185" />
        <g
          id="SvgjsG1186"
          featureKey="Q4qmbg-0"
          transform="matrix(6.033965917459257,0,0,6.033965917459257,1.1382626184474405,-30.117685552630938)"
          fill="#000"
        >
          <path d="M7.9 16.46 l2.08 1.88 c-1.12 1.24 -2.58 1.82 -4.14 1.82 c-2.88 0 -5.2 -2.16 -5.2 -5.08 s2.32 -5.1 5.2 -5.1 c1.56 0 3.02 0.58 4.14 1.82 l-2.08 1.88 c-0.44 -1.02 -1.4 -1.26 -2.06 -1.26 c-1.22 0 -2.24 1.08 -2.24 2.66 c0 1.56 1.02 2.64 2.24 2.64 c0.66 0 1.64 -0.18 2.06 -1.26 z M17.747708333333332 10.26 l-0.4 2.22 c-0.74 -0.32 -0.88 -0.26 -1.22 -0.26 c-1.2 0 -1.9 0.64 -1.9 2.22 l0 5.56 l-2.96 0 l0 -9.88 l2.96 0 l0 0.88 c0.38 -0.68 1.42 -1.02 2.08 -1.02 c0.62 0 0.98 0.02 1.44 0.28 z M22.815416666666664 12.42 c-1.22 0 -2.24 1.08 -2.24 2.66 c0 1.56 1.02 2.64 2.24 2.64 c1.24 0 2.26 -1.08 2.26 -2.64 c0 -1.58 -1.02 -2.66 -2.26 -2.66 z M22.815416666666664 9.98 c2.9 0 5.22 2.18 5.22 5.1 s-2.32 5.08 -5.22 5.08 c-2.88 0 -5.2 -2.16 -5.2 -5.08 s2.32 -5.1 5.2 -5.1 z M38.423125 14.36 l0 5.64 l-2.96 0 l0 -5.64 c0 -1.5 -0.4 -2.16 -1.56 -2.16 c-1.2 0 -1.9 0.66 -1.9 2.16 l0 5.64 l-2.96 0 l0 -9.88 l2.96 0 l0 0.94 c0.76 -0.74 1.8 -1.08 2.66 -1.08 c2.78 0 3.76 1.34 3.76 4.38 z M42.87083333333333 10.12 l0 9.88 l-2.96 0 l0 -9.88 l2.96 0 z M42.95083333333333 7.279999999999999 c0 0.84 -0.68 1.5 -1.58 1.5 c-0.86 0 -1.56 -0.66 -1.56 -1.5 c0 -0.82 0.7 -1.46 1.56 -1.46 c0.9 0 1.58 0.64 1.58 1.46 z M48.018541666666664 15.8 c0 0.72 -0.02 1.92 1.08 1.92 c0.54 0 0.88 -0.22 1.26 -0.5 l0 2.44 c-0.46 0.26 -1.02 0.5 -1.62 0.5 c-2.54 0 -3.68 -1.08 -3.68 -4.36 l0 -3.24 l-1.1 0 l0 -2.44 l1.1 0 l0 -2.84 l2.96 0 l0 2.84 l2.34 0 l0 2.44 l-2.34 0 l0 3.24 z" />
        </g>
      </g>
    </svg>
  );
}
